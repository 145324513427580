import TabsController, { TabsControllerProps } from '../tabs/tabs-controller';
import ClientInputController from '../inputs/client-input/client-input-controller';
import TextInputLabeledController from '../inputs/text-input/text-input-labeled-controller';
import React from 'react';
import {
  ClientFieldKeys,
  ClientFieldTypesRaw,
  ClientType,
} from '../../forms/clients';
import { useTranslation } from 'react-i18next';
import { Control } from 'react-hook-form/dist/types/form';

interface Props<T extends ClientFieldTypesRaw, C>
  extends Omit<TabsControllerProps<T, C>, 'control' | 'name' | 'tabs'> {
  control: Control<T, C>;
}

export const SelectOrAddClient = <T extends ClientFieldTypesRaw, C>({
  control,
  title,
  classNames = {
    contentContainer: '!pt-2',
    wrapper: 'relative z-[15] focus-within:z-50 rounded-lg bg-gray-50 p-2',
  },
  ...rest
}: Props<T, C>) => {
  const { t } = useTranslation();

  return (
    <TabsController
      title={title ?? t('client-searches:fields.client')}
      control={control}
      name={ClientFieldKeys.CLIENT_TYPE as any}
      classNames={classNames}
      tabs={[
        {
          key: ClientType.EXISTING,
          text: t('client-searches:fields.clientType.existing'),
        },
        {
          key: ClientType.NEW,
          text: t('client-searches:fields.clientType.new'),
        },
      ]}
      {...rest}
    >
      <TabsController.Tab key={ClientType.EXISTING}>
        <ClientInputController
          name={ClientFieldKeys.CLIENT_ID}
          id={ClientFieldKeys.CLIENT_ID}
          control={control}
          saveErrorSpace={true}
        />
      </TabsController.Tab>
      <TabsController.Tab key={ClientType.NEW}>
        <div className="mt-2 flex justify-between gap-2 pt-2">
          <TextInputLabeledController
            name={ClientFieldKeys.CLIENT_FIRST_NAME}
            id={ClientFieldKeys.CLIENT_FIRST_NAME}
            control={control}
            labelClassName="mb-1"
            label={t('clients:form.fields.firstName')}
            saveErrorSpace={true}
          />
          <TextInputLabeledController
            name={ClientFieldKeys.CLIENT_LAST_NAME}
            id={ClientFieldKeys.CLIENT_LAST_NAME}
            control={control}
            labelClassName="mb-1"
            label={t('clients:form.fields.lastName')}
            saveErrorSpace={true}
          />
        </div>
        <div className="pt-2">
          <TextInputLabeledController
            name={ClientFieldKeys.CLIENT_PHONE_NUMBER}
            id={ClientFieldKeys.CLIENT_PHONE_NUMBER}
            control={control}
            prefixIcon={<div className="sm:text-sm">+48</div>}
            label={t('clients:form.fields.phoneNumber')}
            labelClassName="mb-1"
            saveErrorSpace={true}
          />
        </div>
        <div className="pt-2">
          <TextInputLabeledController
            name={ClientFieldKeys.CLIENT_EMAIL}
            id={ClientFieldKeys.CLIENT_EMAIL}
            control={control}
            label={t('clients:form.fields.email')}
            autoComplete="email"
            labelClassName="mb-1"
            saveErrorSpace={true}
          />
        </div>
      </TabsController.Tab>
    </TabsController>
  );
};
