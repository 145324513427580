import React from 'react';
import { AppView, AppViewContext } from './use-app-view';
import { useUser } from '../auth/use-user';

const NON_DEFAULT = [
  'b4e45583-7a12-4a08-b8d2-d479740719fd', // ghost
  '3aa7845e-b4fc-44f3-a517-f13b4bb10878', // ko
  '59571096-9057-4424-8af0-696a16247a42', // ko
  '10b1c3b6-44b1-4905-80d4-df5d919f85aa', // Emil
  '1e3bdb13-f4b8-4ba3-b22e-c7921956878a', // father
];

const ANALYTICS_ALLOW_LIST = [
  ...NON_DEFAULT,
  'e3d29b08-5091-4e8c-9dc0-9ebec59d07ad', // hin
  '3aa7845e-b4fc-44f3-a517-f13b4bb10878', // ko
  'c834ae32-e108-47c1-9114-4c341b334828', // CBRE
  'd93862bd-3fa2-4f23-b0fb-009fa976e3b7', // ECHO
  'df86ea46-34e7-4084-be5d-90f3fcfe87c0', // mzuri
  '084be92a-d605-4fae-b6d4-8661c42577a5', // mak dom
  '788aabb4-6e88-4614-94f1-6062b184b35e', // kubatura
  'ba5dc3e5-20ea-4198-a1a0-6e23b24acc6b', // pie1
  'f4ef9e87-779e-4bdf-bbd2-64a038397bf6', // pie2
];

export const AppViewProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const { selectedOrganization } = useUser();

  const isAnalyticsAvailable = ANALYTICS_ALLOW_LIST.includes(
    selectedOrganization.organizationId,
  );

  const [currView, setView] = React.useState(
    isAnalyticsAvailable &&
      !NON_DEFAULT.includes(selectedOrganization.organizationId)
      ? AppView.ANALYTICS_APP
      : AppView.REAL_ESTATE_APP,
  );

  const switchToRealEstateApp = React.useCallback(() => {
    setView(AppView.REAL_ESTATE_APP);
  }, []);

  const switchToAnalyticsApp = React.useCallback(() => {
    setView(AppView.ANALYTICS_APP);
  }, []);

  const viewMatchesAny = React.useCallback(
    (canMatch: AppView[]) => {
      return canMatch.includes(currView);
    },
    [currView],
  );

  return (
    <AppViewContext.Provider
      value={{
        currentView: currView,
        isAnalyticsApp: currView === AppView.ANALYTICS_APP,
        isRealEstateApp: currView === AppView.REAL_ESTATE_APP,
        isParcelApp: currView === AppView.PARCEL_APP,
        switchToRealEstateApp,
        switchToAnalyticsApp,
        viewMatchesAny,
        isAnalyticsAvailable,
      }}
    >
      {children}
    </AppViewContext.Provider>
  );
};
