import { Menu, Transition } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import React from 'react';
import { usePopper } from 'react-popper';

interface Props {
  beforeEnter?: () => void;
  strategy?: 'fixed' | 'absolute';
  items: React.ReactNode;
  itemsWrapperClassName?: string;
}

export const InlineMenu = ({
  beforeEnter = () => {},
  strategy = 'fixed',
  items,
  itemsWrapperClassName = '',
}: Props) => {
  const [referenceElement, setReferenceElement] =
    React.useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] =
    React.useState<HTMLDivElement | null>(null);
  const [active, setActive] = React.useState(false);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    strategy,
    modifiers: [
      { name: 'offset', options: { offset: [0, 8] } },
      { name: 'eventListeners', enabled: active },
    ],
  });

  return (
    <Menu as="div" className="relative inline-block text-left">
      <>
        <Menu.Button
          ref={setReferenceElement}
          className="flex items-center rounded-full text-gray-400 hover:text-gray-600"
        >
          <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
        </Menu.Button>
        <div
          style={styles.popper}
          ref={setPopperElement}
          {...attributes.popper}
          className={`z-30 w-56 ${itemsWrapperClassName}`}
        >
          <Transition
            beforeEnter={() => {
              setActive(true);
              beforeEnter();
            }}
            as={React.Fragment}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
            afterLeave={() => setActive(false)}
          >
            <Menu.Items className="divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              {items}
            </Menu.Items>
          </Transition>
        </div>
      </>
    </Menu>
  );
};
