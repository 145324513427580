import React from 'react';
import {
  FeatureFlag,
  OfferStatus,
  OfferStatusResponse,
  OfferTypeEnum,
} from '../../api/api.types';
import { ANALYTICS_EVENTS } from '../../constants/analytics';
import CopyOriginalOfferLinkAction from './offer-actions/actions/copy-orginal-offer-link-action';
import OpenOriginalOfferAction from './offer-actions/actions/open-original-offer-action';
import DownloadAsPdfAction from './offer-actions/actions/download-as-pdf-action';
import AddToStatusAction from './offer-actions/actions/add-to-status-action';
import { useAnalytics } from '../../context/analytics/use-analytics';
import CallAction from './offer-actions/actions/call-action';
import AddToClientSearchAction from './offer-actions/actions/add-to-client-search-action';
import AssignAction from './offer-actions/actions/assign-action';
import { useUser } from '../../context/auth/use-user';
import CreateMeetingAction from './offer-actions/actions/create-meeting-action';
import RefreshOfferActivityAction from './offer-actions/actions/refresh-offer-activity-action';
import { InlineMenu } from '../menu/inline-menu';

interface Props {
  offerOriginalUrl?: string;
  id: string;
  offerType: OfferTypeEnum;
  selectedOfferStatus?: OfferStatusResponse;
  showStatuses?: boolean;
  contactNumber?: string | null;
  showDownloadPdf?: boolean;
  showAssignment?: boolean;
  downloadPdfFileName?: string;
  strategy?: 'fixed' | 'absolute';
  displayAddToClientSearch?: boolean;
  onClickAddToClientSearch?: () => void;
  displayCreateMeeting?: boolean;
  onClickCreateMeeting?: () => void;
}

const OfferCardMenu = ({
  offerOriginalUrl,
  id,
  offerType,
  selectedOfferStatus,
  contactNumber,
  showAssignment = true,
  showStatuses = true,
  strategy = 'fixed',
  showDownloadPdf = true,
  downloadPdfFileName = 'offer',
  displayAddToClientSearch = false,
  onClickAddToClientSearch = () => {},
  displayCreateMeeting = false,
  onClickCreateMeeting = () => {},
}: Props) => {
  const analytics = useAnalytics();
  const user = useUser();

  return (
    <InlineMenu
      strategy={strategy}
      beforeEnter={() => {
        analytics.track(ANALYTICS_EVENTS.OFFER_MENU_OPENED, {
          offerType,
          offerId: id,
        });
      }}
      items={
        <>
          <div className="py-1">
            {offerOriginalUrl && (
              <OpenOriginalOfferAction offerOriginalUrl={offerOriginalUrl} />
            )}
            <RefreshOfferActivityAction offerType={offerType} offerId={id} />
            {offerOriginalUrl && (
              <CopyOriginalOfferLinkAction
                offerOriginalUrl={offerOriginalUrl}
              />
            )}
            {contactNumber && <CallAction contactNumber={contactNumber} />}
            {showDownloadPdf && (
              <DownloadAsPdfAction
                offerType={offerType}
                offerId={id}
                offerTitle={downloadPdfFileName}
              />
            )}
            {displayCreateMeeting && (
              <CreateMeetingAction onClick={onClickCreateMeeting} />
            )}
            {displayAddToClientSearch && (
              <AddToClientSearchAction onClick={onClickAddToClientSearch} />
            )}
          </div>
          <div className="py-1">
            {showStatuses && (
              <AddToStatusAction
                offerType={offerType}
                offerId={id}
                selectedOfferStatus={selectedOfferStatus ?? null}
                status={OfferStatus.MARKED_FOR_CONTACT}
              />
            )}
            {showAssignment &&
              user.hasFeatureFlag(FeatureFlag.ALWAYS_DISABLE) && (
                <AssignAction offerId={id} />
              )}
          </div>
        </>
      }
    />
  );
};

export default OfferCardMenu;
