import React from 'react';
import { useQuery } from '@tanstack/react-query';
import PersonInput, {
  FILTER_BY_FULL_NAME_OR_PHONE_NUMBER,
  PersonInputProps,
} from '../person-input/person-input';
import { getClients } from '../../../api/clients/clients';
import { formatPhone } from '../../../constants/phone-formatter';

interface Props extends Omit<PersonInputProps, 'people'> {
  id: string;
}

const ClientInput = ({ value, onChange, ...rest }: Props) => {
  const { data: clients = [] } = useQuery(['clients'], getClients);

  return (
    <PersonInput
      value={value}
      {...rest}
      onChange={onChange}
      filterFunction={FILTER_BY_FULL_NAME_OR_PHONE_NUMBER}
      people={
        clients.map((c) => ({
          firstName: c.first_name,
          lastName: c.last_name,
          id: c.client_id,
          phoneNumber: c.phone_number,
          extraText: (
            <span className="ml-2 text-xs">{formatPhone(c.phone_number)}</span>
          ),
        })) ?? []
      }
    />
  );
};

export default ClientInput;
export type { Props as ClientInputProps };
