import { useMutation, useQueryClient } from '@tanstack/react-query';
import { postAddClient } from '../../api/clients/clients';
import { AxiosError } from 'axios';
import { useToast } from '../use-toast/use-toast';
import { useTranslation } from 'react-i18next';
import { AddClientRequest } from '../../api/api.types';
import {
  ClientFieldKeys,
  ClientFieldTypesValidated,
  ClientType,
} from '../../forms/clients';

interface Props {
  onError?: (
    e: AxiosError,
    variables: { body: AddClientRequest },
  ) => Promise<void>;
  onSuccess?: () => Promise<void>;
}

export const useCreateClient = ({
  onError = () => Promise.resolve(),
  onSuccess = () => Promise.resolve(),
}: Props = {}) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(['clients']);
  const { displayErrorToast, displayDefaultErrorToasts } = useToast();

  const { mutateAsync: createClient, isLoading } = useMutation(postAddClient, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['clients']);
      await queryClient.invalidateQueries(['contactedClients']);
      await onSuccess();
    },
    onError: async (e: AxiosError, variables) => {
      if (
        e.response?.status === 422 &&
        (e.response?.data as any)?.message?.includes('integrity constraint')
      ) {
        displayErrorToast({
          title: t('clients:select.clientDuplicate.title'),
          body: t('clients:select.clientDuplicate.body'),
        });
      } else {
        displayDefaultErrorToasts(e);
      }
      await onError(e, variables);
    },
  });

  return {
    addOrSelect: async (data: ClientFieldTypesValidated) => {
      if (data[ClientFieldKeys.CLIENT_TYPE] === ClientType.EXISTING) {
        return data[ClientFieldKeys.CLIENT_ID];
      }

      const phoneNumber = data[ClientFieldKeys.CLIENT_PHONE_NUMBER].replaceAll(
        ' ',
        '',
      );
      const response = await createClient({
        body: {
          first_name: data[ClientFieldKeys.CLIENT_FIRST_NAME],
          last_name: data[ClientFieldKeys.CLIENT_LAST_NAME],
          phone_number: phoneNumber.startsWith(`+`)
            ? phoneNumber
            : `+48${phoneNumber}`,
          email: data[ClientFieldKeys.CLIENT_EMAIL] ?? undefined,
        },
      });
      return response.client_id;
    },
    addClient: async (data: AddClientRequest) => {
      await createClient({ body: data });
    },
    isLoading,
  };
};
