import { OfferTypeEnum } from '../api.types';
import { axios } from '../axios';

export const getOfferPdfByTypeAndId = (
  offerType: OfferTypeEnum,
  offerId: string,
): Promise<any> => {
  return axios
    .get(`/pdf_generation/${offerType}/${offerId}`, {
      responseType: 'blob',
    })
    .then((resp) => resp.data);
};

export const getOfferVariantPdfById = (
  offerVariantId: string,
): Promise<any> => {
  return axios
    .get(`/pdf_generation/variants/${offerVariantId}`, {
      responseType: 'blob',
    })
    .then((resp) => resp.data);
};

export const getExportedOfferPdfById = (
  exportedOfferId: string,
): Promise<any> => {
  return axios
    .get(`/pdf_generation/exported-offers/${exportedOfferId}`, {
      responseType: 'blob',
    })
    .then((resp) => resp.data);
};

export const getInternalAvmPdfById = ({
  avmEvaluationId,
  language,
  version,
}: {
  avmEvaluationId: string;
  language: string;
  version?: number;
}): Promise<any> => {
  return axios
    .get(`/pdf/generate/internal_avm/${avmEvaluationId}`, {
      params: { language, version },
      responseType: 'blob',
    })
    .then((resp) => resp.data);
};
