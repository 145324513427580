import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

interface Props {
  page: number;
  recordsPerPage: number;
  totalRecords: number;
  isLoading?: boolean;
  className?: string;
}

const PaginationItemsSummary = ({
  page,
  recordsPerPage,
  totalRecords,
  isLoading,
  className = '',
}: Props) => {
  const { t } = useTranslation('common');

  if (isLoading) {
    return (
      <div className={className}>
        <div className="mt-auto h-5 w-52 animate-pulse rounded bg-gray-200" />
      </div>
    );
  }

  return (
    <div>
      <p className={`text-sm text-gray-700 ${className}`}>
        <Trans
          i18nKey="common:pagination.paginationSummary"
          t={t}
          values={{
            first: Math.min(
              page * recordsPerPage - recordsPerPage + 1,
              totalRecords,
            ),
            last: Math.min(page * recordsPerPage, totalRecords),
            total: totalRecords,
          }}
          components={{
            highlightWrapper: <span className="font-bold font-medium" />,
          }}
        />
      </p>
    </div>
  );
};

export default PaginationItemsSummary;
