import { LOCATION_FIELDS } from '../constants/location-fields';

export interface Address {
  address_street?: string | null;
  address_street_number?: string | null;
  address_district?: string | null;
  address_city?: string | null;
  address_state?: string | null;
  address_county?: string | null;
  address_community?: string | null;
  address_postcode?: string | null;
}

export const isAddress = (value: any): value is Address => {
  const keys = [
    'address_street',
    'address_street_number',
    'address_district',
    'address_city',
    'address_state',
    'address_county',
    'address_community',
    'address_postcode',
  ];
  return (
    typeof value === 'object' &&
    keys.every(
      (key) =>
        ['string', 'undefined'].includes(typeof value?.[key]) ||
        value?.[key] === null,
    ) &&
    keys.some((key) => value?.[key] !== undefined)
  );
};

export function formatAddress(address: Address) {
  return (
    [
      address.address_state,
      address.address_county,
      address.address_community === address.address_county
        ? null
        : address.address_community,
      address.address_city === address.address_community
        ? null
        : address.address_city,
      address.address_district,
      address.address_street,
    ]
      .filter((x) => x !== null && x !== undefined)
      .join(', ') +
    (address.address_street_number ? ` ${address.address_street_number}` : '')
  );
}

export function formatFullAddress(address: Address) {
  return (
    [
      address.address_state,
      address.address_county,
      address.address_community,
      address.address_city,
      address.address_district,
      address.address_street,
    ]
      .filter((x) => x !== null && x !== undefined)
      .join(', ') +
    (address.address_street_number ? ` ${address.address_street_number}` : '')
  );
}

export function formatSimpleAddress(address: Address) {
  return address.address_street
    ? [
        [address.address_street, address.address_street_number]
          .filter((x) => x !== null && x !== undefined)
          .join(' '),
        [address.address_postcode, address.address_district]
          .filter((x) => x !== null && x !== undefined)
          .join(' '),
        address.address_city,
      ]
        .filter((term) => term != '')
        .join(', ')
    : [
        [address.address_city, address.address_street_number]
          .filter((x) => x !== null && x !== undefined)
          .join(' '),
        [address.address_postcode, address.address_community]
          .filter((x) => x !== null && x !== undefined)
          .join(' '),
      ]
        .filter((term) => term != '')
        .join(', ');
}

export function getAddressFromOffer(offer: any): Address {
  return {
    address_street: offer.address_street,
    address_street_number: offer.address_street_number,
    address_district: offer.address_district,
    address_city: offer.address_city,
    address_state: offer.address_state,
    address_county: offer.address_county,
    address_community: offer.address_community,
  };
}

export function simplifyAddressString(address: string, separator = ', ') {
  const parts = address.split(separator);

  let prev = null;

  const result = [];

  for (const part of parts) {
    if (part !== prev) {
      result.push(part);
    }
    prev = part;
  }

  return result.join(separator);
}

export function resolveAddressParts(
  address: string,
  separator = ', ',
): Address {
  const parts = address.split(separator);

  const result = {} as Address;

  for (let i = 0; i < parts.length; i++) {
    // @ts-ignore
    result[LOCATION_FIELDS[i]] = parts[i];
  }

  return result;
}
