import React from 'react';
import { useTranslation } from 'react-i18next';
import DrawerWithDescription, {
  DRAWER_WITH_DESCRIPTION_ANIMATION_DURATION,
} from '../../drawers/drawer-with-description';
import Button from '../../button/button';
import {
  RawCreateMeetingFieldTypes,
  useMeetingCreateForm,
} from '../../../hooks/use-meeting/use-meeting-create-form';
import { useQuery } from '@tanstack/react-query';
import TextInputLabeledController from '../../inputs/text-input/text-input-labeled-controller';
import { MeetingFieldKeys } from '../../../forms/meeting';
import TextAreaInputLabeledController from '../../inputs/text-area-input/text-area-input-labeled-controller';
import DateTimeInputLabeledController from '../../inputs/date-time-input/date-time-input-labeled-controller';
import OrganizationMemberInputController from '../../inputs/organization-member-input/organization-member-input-controller';
import { addHours, endOfDay, format, isDate, isValid } from 'date-fns';
import { getClientDetails } from '../../../api/clients/clients';
import { useUser } from '../../../context/auth/use-user';
import { ClientFieldKeys, ClientType } from '../../../forms/clients';
import { FeatureFlag } from '../../../api/api.types';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { getAllowanceForFairMeetingAssignment } from '../../../api/meetings/meetings-endpoints';
import { SelectOrAddClient } from '../../clients/select-or-add-client';

interface Props {
  isOpen: boolean;
  close: () => void;
  offerId?: string;
  defaults?: Partial<RawCreateMeetingFieldTypes>;
}

const MeetingCreateDrawer = ({
  isOpen,
  close,
  offerId,
  defaults = {},
}: Props) => {
  const user = useUser();

  const { data: useFairAssignments = false } = useQuery(
    ['fair-meetings-allowance', offerId],
    // todo(Emil): invalidated on team changes
    () => getAllowanceForFairMeetingAssignment(offerId ?? ''),
    {
      enabled:
        isOpen &&
        !!offerId &&
        user.hasFeatureFlag(FeatureFlag.FAIR_MEETINGS_ENABLED_ASSIGNMENT),
    },
  );

  const { t } = useTranslation(['meetings', 'clients']);

  const { submit, isLoading, control, reset, formState, setValue, watch } =
    useMeetingCreateForm({
      defaults,
      offerId,
      afterSuccessSubmit: () => {
        onClose();
      },
      useFairAssignments,
    });

  const startTimeValue = watch(MeetingFieldKeys.START_TIME);
  const endTimeValue = watch(MeetingFieldKeys.END_TIME);
  const withValue = watch(ClientFieldKeys.CLIENT_ID);
  const clientType = watch(ClientFieldKeys.CLIENT_TYPE);
  const newClientFirstName = watch(ClientFieldKeys.CLIENT_FIRST_NAME);
  const newClientLastName = watch(ClientFieldKeys.CLIENT_LAST_NAME);

  const shouldAutoAssign =
    useFairAssignments &&
    !!startTimeValue &&
    isDate(startTimeValue) &&
    isTomorrowOrAfter(new Date(startTimeValue)) &&
    !!offerId;

  const { data: clientData } = useQuery(
    ['clients', withValue],
    () => getClientDetails(withValue!),
    { enabled: !!withValue && clientType === ClientType.EXISTING },
  );

  React.useEffect(() => {
    if (
      startTimeValue &&
      isValid(startTimeValue) &&
      (!endTimeValue || !isValid(endTimeValue))
    ) {
      setValue(MeetingFieldKeys.END_TIME, addHours(startTimeValue, 1));
    }
  }, [startTimeValue]);

  React.useEffect(() => {
    if (isOpen) {
      reset({
        [MeetingFieldKeys.TITLE]: '',
        [MeetingFieldKeys.DESCRIPTION]: '',
        [MeetingFieldKeys.START_TIME]: null,
        [MeetingFieldKeys.END_TIME]: null,
        [MeetingFieldKeys.ASSIGNED_USER_ID]: null,
        [ClientFieldKeys.CLIENT_ID]: null,
        [ClientFieldKeys.CLIENT_TYPE]: ClientType.EXISTING,
        [ClientFieldKeys.CLIENT_FIRST_NAME]: undefined,
        [ClientFieldKeys.CLIENT_LAST_NAME]: undefined,
        [ClientFieldKeys.CLIENT_PHONE_NUMBER]: undefined,
        ...defaults,
      });
    }
  }, [isOpen]);

  const onClose = () => {
    close();
    setTimeout(() => {
      reset({
        [MeetingFieldKeys.TITLE]: '',
        [MeetingFieldKeys.DESCRIPTION]: '',
        [MeetingFieldKeys.START_TIME]: null,
        [MeetingFieldKeys.END_TIME]: null,
        [MeetingFieldKeys.ASSIGNED_USER_ID]: null,
        [ClientFieldKeys.CLIENT_ID]: null,
        [ClientFieldKeys.CLIENT_TYPE]: ClientType.EXISTING,
        [ClientFieldKeys.CLIENT_FIRST_NAME]: undefined,
        [ClientFieldKeys.CLIENT_LAST_NAME]: undefined,
        [ClientFieldKeys.CLIENT_PHONE_NUMBER]: undefined,
        ...defaults,
      });
    }, DRAWER_WITH_DESCRIPTION_ANIMATION_DURATION);
  };

  React.useEffect(() => {
    if (
      !formState.dirtyFields.title &&
      (clientData || startTimeValue || newClientLastName || newClientFirstName)
    ) {
      const parts = [];

      if (clientData && clientType === ClientType.EXISTING) {
        parts.push(
          t('meetings:drawer.create.titlePropositions.linkedClient', {
            fullName: `${clientData.first_name} ${clientData.last_name}`,
          }),
        );
      } else if (
        (newClientFirstName || newClientLastName) &&
        clientType === ClientType.NEW
      ) {
        parts.push(
          t('meetings:drawer.create.titlePropositions.linkedClient', {
            fullName: `${newClientFirstName ?? ''}${
              newClientFirstName && newClientLastName ? ' ' : ''
            }${newClientLastName ?? ''}`,
          }),
        );
      }

      if (startTimeValue) {
        parts.push(
          t('meetings:drawer.create.titlePropositions.startTime', {
            date: format(startTimeValue, user.settings.dateFormat),
          }),
        );
      }

      const connectedParts = parts.join(' ');
      const firstLetterUppercase =
        connectedParts.charAt(0).toUpperCase() + connectedParts.slice(1);
      setValue(MeetingFieldKeys.TITLE, firstLetterUppercase);
    }
  }, [
    formState.dirtyFields.title,
    clientData,
    startTimeValue,
    clientType,
    newClientFirstName,
    newClientLastName,
  ]);

  return (
    <DrawerWithDescription
      title={t('meetings:drawer.create.title')}
      description={
        offerId
          ? t('meetings:drawer.create.descriptionWithOffer')
          : t('meetings:drawer.create.description')
      }
      buttons={
        <div className="flex flex-row">
          <Button
            type="button"
            variant="secondary"
            className="ml-auto mt-3 px-3 py-2"
            onClick={onClose}
            disabled={isLoading}
          >
            {t('meetings:drawer.create.cancel')}
          </Button>
          <Button
            type="submit"
            variant="primary"
            form="add-meeting-with-offer"
            className="ml-3 mt-3 px-3 py-2"
            isLoading={isLoading}
            disabled={!formState.isValid && formState.isSubmitted}
          >
            {t('meetings:drawer.create.submit')}
          </Button>
        </div>
      }
      close={onClose}
      isOpen={isOpen}
    >
      <form
        id="add-meeting-with-offer"
        className="flex h-full flex-col"
        onSubmit={submit}
      >
        <div className="flex flex-1 flex-col justify-between">
          <div className="px-4 sm:px-6">
            <div className="space-y-2 pb-5 pt-6">
              <DateTimeInputLabeledController
                label={t('meetings:fields.startTime')}
                labelClassName="mb-1"
                control={control}
                name={MeetingFieldKeys.START_TIME}
                isRequired={true}
                saveErrorSpace={true}
              />
              <DateTimeInputLabeledController
                label={t('meetings:fields.endTime')}
                labelClassName="mb-1"
                control={control}
                name={MeetingFieldKeys.END_TIME}
                isRequired={true}
                saveErrorSpace={true}
              />
              {shouldAutoAssign ? (
                <FairAssignmentInfo />
              ) : (
                <div className="relative !mb-4 focus-within:z-50">
                  <OrganizationMemberInputController
                    label={t('meetings:fields.assignedUser')}
                    containerClassName="!mt-1"
                    name={MeetingFieldKeys.ASSIGNED_USER_ID}
                    id={MeetingFieldKeys.ASSIGNED_USER_ID}
                    control={control}
                    allowAssignToMyself={true}
                  />
                </div>
              )}
              <div className="relative !mb-6 focus-within:z-50">
                <SelectOrAddClient control={control} />
              </div>
              <TextInputLabeledController
                name={MeetingFieldKeys.TITLE}
                id={MeetingFieldKeys.TITLE}
                control={control}
                label={t('meetings:fields.title')}
                labelClassName="mb-1"
                isRequired={true}
                saveErrorSpace={true}
              />
              <TextAreaInputLabeledController
                name={MeetingFieldKeys.DESCRIPTION}
                id={MeetingFieldKeys.DESCRIPTION}
                control={control}
                label={t('meetings:fields.description')}
                labelClassName="mb-1"
                rows={3}
              />
            </div>
          </div>
        </div>
      </form>
    </DrawerWithDescription>
  );
};

export default MeetingCreateDrawer;

const isTomorrowOrAfter = (date: Date) => {
  // todo - consider timezone support for WAW
  return date > endOfDay(new Date());
};

const FairAssignmentInfo = () => {
  const { t } = useTranslation(['meetings']);
  return (
    <div className="!mb-6 rounded-md bg-yellow-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationTriangleIcon
            aria-hidden="true"
            className="h-5 w-5 text-yellow-400"
          />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-yellow-800">
            {t('meetings:drawer.create.fairAssignment.title')}
          </h3>
          <div className="mt-2 text-sm text-yellow-700">
            <p>{t('meetings:drawer.create.fairAssignment.description')}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
