import {
  AddressAutosuggestionResponse,
  AddressResponse,
  FullAddressAutosuggestionResponse,
} from '../api.types';
import { axios } from '../axios';
import { Address } from '../../models/address';
import { stringify } from 'query-string';

export const getStates = (): Promise<AddressResponse[]> =>
  axios.get<AddressResponse[]>('/address/states').then((resp) => resp.data);

export const getCounties = (state: string): Promise<AddressResponse[]> =>
  axios
    .get<AddressResponse[]>(`/address/states/${state}/counties`)
    .then((resp) => resp.data);

export const getCommunities = (
  state: string,
  county: string,
): Promise<AddressResponse[]> =>
  axios
    .get<AddressResponse[]>(
      `/address/states/${state}/counties/${county}/communities`,
    )
    .then((resp) => resp.data);

export const getCities = (
  state: string,
  county: string,
): Promise<AddressResponse[]> =>
  axios
    .get<AddressResponse[]>(
      `/address/states/${state}/counties/${county}/cities`,
    )
    .then((resp) => resp.data);

export const getDistricts = (
  state: string,
  county: string,
  city: string,
): Promise<AddressResponse[]> =>
  axios
    .get<AddressResponse[]>(
      `/address/states/${state}/counties/${county}/cities/${city}/districts`,
    )
    .then((resp) => resp.data);

export const getAddressAutocomplete = (query: string, size: number) => {
  return axios
    .get<AddressAutosuggestionResponse[]>(`/address/autocomplete`, {
      params: { query, size },
    })
    .then((resp) => resp.data);
};

export const getFullAddressAutocomplete = (query: string, size: number) => {
  return axios
    .get<FullAddressAutosuggestionResponse[]>(`/full-address/autocomplete`, {
      params: { query, size },
    })
    .then((resp) => resp.data);
};

export const getDefaultAutocomplete = (query: string, size: number) => {
  return axios
    .get<AddressAutosuggestionResponse[]>(`/default-address/autocomplete`, {
      params: { query, size },
    })
    .then((resp) => resp.data);
};

export const getBoundsForLocation = ({
  address_state,
  address_county,
  address_community,
  address_district,
  address_city,
  address_street,
  proximity,
}: Address & { proximity?: number | null }) => {
  return axios
    .get(`/address/geojson`, {
      params: {
        address_state,
        address_county,
        address_community,
        address_district: address_district ? address_district : address_street,
        address_city,
        proximity,
      },
    })
    .then((resp) => resp.data);
};

export const getLatLongForLocation = ({
  address_state,
  address_county,
  address_community,
  address_district,
  address_city,
  address_street,
  address_street_number,
}: Address) => {
  return axios
    .get<{
      latitude: number;
      longitude: number;
    }>(`/address/geocoding`, {
      params: {
        address_state,
        address_county,
        address_community,
        address_district,
        address_city,
        address_street,
        address_street_number,
      },
    })
    .then((resp) => resp.data);
};

export enum OSMLocationType {
  PARKING = 'parking',
  TRAM_STOP = 'tram_stop',
  PARK = 'park',
  BORDER_STATION = 'border_station',
  TRAFFIC_PARK = 'traffic_park',
  STATION = 'station',
  RESTAURANT = 'restaurant',
  PLATFORM = 'platform',
  BENCH = 'bench',
  BUS_STATION = 'bus_station',
  BAR = 'bar',
  SUBWAY = 'subway',
  SCHOOL = 'school',
  SHELTER = 'shelter',
  CONVENT = 'convent',
  SOCIAL_FACILITY = 'social_facility',
  CAFE = 'cafe',
  BUS_STOP = 'bus_stop',
  ANIMAL_SHELTER = 'animal_shelter',
  UNIVERSITY = 'university',
}

// Interface for PointOfInterestResponse
export interface PointOfInterestResponse {
  id: string;
  location_type: OSMLocationType;
  name: string;
  latitude: number;
  longitude: number;
  tags: Record<string, any>;
}

export const getPointsOfInterest = ({
  latMin,
  latMax,
  lonMin,
  lonMax,
  locationTypes,
}: {
  latMin: number;
  latMax: number;
  lonMin: number;
  lonMax: number;
  locationTypes: OSMLocationType[];
}) => {
  return axios
    .get<PointOfInterestResponse[]>(`/address/poi`, {
      params: {
        lat_min: latMin,
        lat_max: latMax,
        lon_min: lonMin,
        lon_max: lonMax,
        location_type: locationTypes,
      },
      paramsSerializer: (params) => {
        return stringify(params);
      },
    })
    .then((resp) => resp.data);
};
