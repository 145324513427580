import { convertToSchema } from '../../schemas/schemas';
import {
  OfferDetailsResponse,
  OfferTypeEnum,
  OfferVariantDetailsResponse,
} from '../../api/api.types';
import { formatAddress } from '../../models/address';
import { MAX_IMAGES } from '../offer-photos/offer-photos-upload';
import { getNextId } from '../../utils/get-next-id';
import { ALL_VARIANT_FIELD_NAMES } from './offer-variant-fields';

export const formToOfferSchema = (form: any, offerType?: OfferTypeEnum) => {
  const {
    address,
    location,
    floor_no_lower_bound,
    total_levels_in_building_lower_bound,
    ...filteredForm
  } = form;

  const addressValue = address?.[0]?.value;

  const offerVariant = {
    ...filteredForm,
    title: filteredForm.title ?? ' ',
    description: filteredForm.description ?? ' ',
    location_latitude: location.latitude,
    location_longitude: location.longitude,
    location_approximation: location.approximation,
    address_city: addressValue?.address_city ?? ' ',
    address_state: addressValue?.address_state ?? ' ',
    address_county: addressValue?.address_county ?? ' ',
    address_community: addressValue?.address_community ?? ' ',
    floor_no_lower_bound: floor_no_lower_bound ?? null,
    floor_no_upper_bound: floor_no_lower_bound ?? null,
    total_levels_in_building_lower_bound:
      total_levels_in_building_lower_bound ?? null,
    total_levels_in_building_upper_bound:
      total_levels_in_building_lower_bound ?? null,
    offer_type: offerType ?? OfferTypeEnum.APARTMENT_SALES,
    price_currency: addressValue?.price_currency ?? 'PLN',
    address_country: addressValue?.address_country ?? 'Poland',
    ...(filteredForm.property_size && filteredForm.price
      ? { price_per_meter: filteredForm.price / filteredForm.property_size }
      : {}),
  };
  return convertToSchema(
    offerType ?? OfferTypeEnum.APARTMENT_SALES,
    offerVariant,
  );
};

export const offerDetailsToForm = (data: OfferDetailsResponse) => {
  const offer = data.offer as any;
  const anyOffer = data.super_offer ? data.super_offer : offer;

  const address = {
    address_city: anyOffer.address_city ?? '',
    address_state: anyOffer.address_state ?? '',
    address_county: anyOffer.address_county ?? '',
    address_community: anyOffer.address_community ?? '',
  };

  // using location from first offer
  const offerForm = {
    description: offer.description,
    title: offer.title,
    location: {
      latitude: offer.location_latitude,
      longitude: offer.location_longitude,
      approximation: offer.location_approximation,
    },
    address_street: anyOffer.address_street ?? '',
    address_district: anyOffer.address_district ?? '',
    address: [
      {
        label: formatAddress(address),
        value: address,
      },
    ],
  } as any;

  ALL_VARIANT_FIELD_NAMES.forEach((field) => {
    if (anyOffer[field] !== null) {
      if (Array.isArray(anyOffer[field])) {
        offerForm[field] = anyOffer[0];
      } else {
        offerForm[field] = anyOffer[field];
      }
    } else {
      offerForm[field] = '';
    }
  });
  return offerForm;
};

export const offerVariantToForm = (data: OfferVariantDetailsResponse) => {
  const offer = data.variant_data;

  const address = {
    address_city: offer.address_city ?? '',
    address_state: offer.address_state ?? '',
    address_county: offer.address_county ?? '',
    address_community: offer.address_community ?? '',
  };

  // using location from first offer
  const offerForm = {
    description: offer.description,
    title: offer.title,
    location: {
      latitude: offer.location_latitude,
      longitude: offer.location_longitude,
      approximation: offer.location_approximation,
    },
    address_street: offer.address_street ?? '',
    address_district: offer.address_district ?? '',
    address: [
      {
        label: formatAddress(address),
        value: address,
      },
    ],
  } as any;

  ALL_VARIANT_FIELD_NAMES.forEach((field) => {
    if (offer[field] !== null) {
      offerForm[field] = offer[field];
    } else {
      offerForm[field] = '';
    }
  });
  return offerForm;
};

function removeDuplicates(photosUrls: string[]): string[] {
  const seen = new Set<string>();
  return photosUrls.filter((url) => {
    if (!seen.has(url)) {
      seen.add(url);
      return true;
    }
    return false;
  });
}

export const offerImagesToForm = (data: OfferDetailsResponse) => {
  const offer = data.offer as any;
  const superOffer = data.super_offer as any;
  const imagesCandidates = [
    data?.downloaded_photos_urls?.downloaded_urls,
    superOffer?.downloaded_photos_urls,
    offer?.photos_urls,
    superOffer?.photos_urls,
  ].filter((e) => e && e.length > 0);

  return postProcessImages(imagesCandidates[0] ?? []);
};

export const offerVariantImagesToForm = (data: OfferVariantDetailsResponse) => {
  return postProcessImages(data.photos_urls);
};

export const postProcessImages = (images: string[]) => {
  return (
    removeDuplicates(images)
      .slice(0, MAX_IMAGES)
      .map((url: string) => ({
        dataURL: url,
        id: getNextId(),
      })) ?? []
  );
};
