import React from 'react';
import { Combobox } from '@headlessui/react';
import { classNames } from '../../../style/class-names';
import {
  CheckIcon,
  ChevronDownIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid';
import AppUserAvatar from '../../avatar/app-user-avatar';

export interface PersonInputOption {
  id: string;
  firstName: string;
  lastName: string;
  extraText?: string | React.ReactNode;
  phoneNumber?: string;
  avatarUrl?: string | null;
}

export interface Props {
  value: string | null;
  onChange: (personId: string | null) => void;
  label?: string;
  showLabel?: boolean;
  people?: PersonInputOption[];
  placeholder?: string;
  errorMessage?: string;
  isError?: boolean;
  displayFormatter?: (person?: PersonInputOption) => string;
  sortFunction?: (left: PersonInputOption, right: PersonInputOption) => number;
  filterFunction?: (person: PersonInputOption, query: string) => boolean;
  containerClassName?: string;
  saveErrorSpace?: boolean;
  componentWrapperClassName?: string;
  children?: React.ReactNode;
}

const PersonInput = ({
  value,
  onChange,
  label = '',
  showLabel = true,
  people = [],
  placeholder,
  errorMessage,
  isError,
  containerClassName = '',
  componentWrapperClassName = '',
  displayFormatter = (person?: PersonInputOption) => getFullName(person),
  filterFunction = FILTER_BY_FULL_NAME,
  sortFunction = SORT_BY_FULL_NAME_EXTRA_TEXT_DESC,
  saveErrorSpace = false,
  children,
}: Props) => {
  const [query, setQuery] = React.useState('');

  const filteredPeople =
    query === ''
      ? people
      : people.filter((person) => filterFunction(person, query));

  const sortedPeople = [...filteredPeople].sort(sortFunction);

  return (
    <Combobox
      as="div"
      value={value}
      onChange={onChange}
      nullable
      className={`relative ${componentWrapperClassName}`}
    >
      <Combobox.Label
        className={`${
          showLabel ? '' : 'sr-only'
        } block text-sm font-medium text-gray-700`}
      >
        {label}
      </Combobox.Label>
      <div className={`relative mt-2 ${containerClassName}`}>
        <Combobox.Input
          placeholder={placeholder}
          autoComplete="off"
          className={`w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-12 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm ${
            isError
              ? 'text-red-900 placeholder-red-300 ring-red-300 focus:ring-red-500'
              : 'text-gray-900 ring-gray-300 focus:ring-indigo-600 '
          }`}
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(personId: string) =>
            displayFormatter(people.find((p) => p.id === personId))
          }
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          {value ? (
            <span
              className="absolute inset-y-0 right-0 flex cursor-pointer items-center pr-2"
              onClick={(e) => {
                e.stopPropagation();
                onChange(null);
              }}
            >
              <XMarkIcon
                className="h-5 w-5 cursor-pointer text-gray-400"
                aria-hidden="true"
              />
            </span>
          ) : (
            <ChevronDownIcon
              className={`h-5 w-5 ${
                isError ? 'text-red-500' : 'text-gray-400 '
              }`}
              aria-hidden="true"
            />
          )}
        </Combobox.Button>
        {sortedPeople.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {sortedPeople.map((person) => (
              <Combobox.Option
                key={person.id}
                value={person.id}
                className={({ active }) =>
                  classNames(
                    'relative cursor-default select-none py-2 pl-3 pr-9',
                    active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                  )
                }
              >
                {({ active }) => (
                  <>
                    <div className="flex items-center">
                      <AppUserAvatar
                        size="md"
                        firstName={person.firstName}
                        lastName={person.lastName}
                        avatarUrl={person.avatarUrl}
                        userId={person.id}
                      />
                      <span
                        className={classNames(
                          'ml-3 truncate',
                          person.id === value && 'font-semibold',
                        )}
                      >
                        {getFullName(person)} {person.extraText}
                      </span>
                    </div>
                    {person.id === value && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 right-0 flex items-center rounded-md pr-4',
                          active ? 'text-white' : 'text-indigo-600',
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
      {children}
      {saveErrorSpace && (
        <p
          className={`mt-2 text-xs text-red-600 ${
            saveErrorSpace ? 'whitespace-pre-wrap' : ''
          }`}
        >
          {isError ? errorMessage : ' '}
        </p>
      )}
    </Combobox>
  );
};

export default PersonInput;
export type { Props as PersonInputProps };

export const getFullName = (person?: PersonInputOption | null) => {
  if (person?.firstName && person.lastName) {
    return `${person.firstName} ${person.lastName}`;
  } else if (person?.firstName) {
    return person?.firstName;
  } else if (person?.lastName) {
    return person.lastName;
  } else {
    return '';
  }
};

export const SORT_BY_FULL_NAME_EXTRA_TEXT_DESC = (
  left: PersonInputOption,
  right: PersonInputOption,
) => {
  let leftFullName = getFullName(left).toUpperCase();
  let leftExtraText = left.extraText ?? '';
  let rightFullName = getFullName(right).toUpperCase();
  let rightExtraText = right.extraText ?? '';

  if (leftFullName < rightFullName) {
    return -1;
  }
  if (leftFullName > rightFullName) {
    return 1;
  }
  if (leftExtraText < rightExtraText) {
    return -1;
  }
  if (leftExtraText > rightExtraText) {
    return 1;
  }
  return 0;
};

export const FILTER_BY_FULL_NAME = (
  person: PersonInputOption,
  query: string,
) => {
  return getFullName(person).toLowerCase().includes(query.toLowerCase());
};

export const FILTER_BY_FULL_NAME_OR_PHONE_NUMBER = (
  person: PersonInputOption,
  query: string,
) => {
  return Boolean(
    getFullName(person).toLowerCase().includes(query.toLowerCase()) ||
      (person.phoneNumber &&
        person.phoneNumber.includes(query.replaceAll(' ', ''))),
  );
};
