import React from 'react';
import { Link, matchPath } from 'react-router-dom';
import { NavElement } from './navigation';
import { useTranslation } from 'react-i18next';
import { classNames } from '../../style/class-names';
import SubscriptionFeatureLock from '../subscriptions/subscription-feature-lock';

interface Props {
  className?: string;
  item: NavElement;
  pathname: string;
  onClick?: () => void;
}

const NavItem = ({ className = '', item, pathname, onClick }: Props) => {
  const { t } = useTranslation('navigation');

  return (
    <SubscriptionFeatureLock
      requiredSubscriptionTier={item.requiredSubscriptionTier}
    >
      <Link
        key={item.name}
        to={item.to}
        className={classNames(
          matchPath({ path: item.pathPrefix, end: false }, pathname)
            ? 'border-indigo-600 bg-violet-50 text-indigo-600'
            : 'border-transparent text-gray-600 hover:bg-gray-50 hover:text-gray-900',
          'group flex select-none items-center border-l-4 px-3 py-2 text-base font-medium',
          className,
        )}
        onClick={() => onClick?.()}
      >
        <item.icon
          className={classNames(
            matchPath({ path: item.pathPrefix, end: false }, pathname)
              ? 'text-indigo-600'
              : 'text-gray-400 group-hover:text-gray-500',
            'mr-4 h-6 w-6 flex-shrink-0',
          )}
          aria-hidden="true"
        />
        <span className="truncate">{t(`drawer.${item.name}`)}</span>
        {item.displayNewBadge ? (
          <span className="ml-auto inline-flex items-center rounded-md bg-indigo-100 px-2 py-1 text-xs font-medium text-indigo-700">
            {t(`navigation:drawer.new`)}
          </span>
        ) : null}
      </Link>
    </SubscriptionFeatureLock>
  );
};

export default NavItem;
