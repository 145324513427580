import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  postAddClientSearch,
  postAddOfferToClientSearch,
  postChangeClientSearchOfferStatus,
} from '../../api/client-searches/client-searches';
import { AxiosError } from 'axios';
import { defaultErrorToasts } from '../../utils/default-toasts';
import { useToast } from '../use-toast/use-toast';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { OfferSearchStateEnum, OfferTypeEnum } from '../../api/api.types';
import {
  ClientSearchSelection,
  UseAddToClientSearchClientSearchData,
} from './use-add-to-client-search-client-search';
import {
  ClientSelection,
  UseAddToClientSearchClientData,
} from './use-add-to-client-search-client';
import { ANALYTICS_EVENTS } from '../../constants/analytics';
import { useAnalytics } from '../../context/analytics/use-analytics';
import { useVariantCreationFromOffer } from '../../components/offer-variant/use-variant-creation-from-offer';
import { useCreateClient } from '../use-client/use-create-client';
import { ClientType } from '../../forms/clients';

interface Props {
  client: UseAddToClientSearchClientData;
  clientSearch: UseAddToClientSearchClientSearchData;
  offer: {
    id: string;
    type: OfferTypeEnum;
  };
}

export const useAddToClientSearchSubmit = ({
  offer,
  client,
  clientSearch,
}: Props) => {
  const analytics = useAnalytics();
  const { t } = useTranslation(['client-searches', 'common', 'clients']);
  const { displaySuccessToast, displayErrorToast } = useToast();
  const queryClient = useQueryClient();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [wasLastSubmitSuccess, setWasLastSubmitSuccess] =
    React.useState<boolean>();

  const [clientSearchId, setClientSearchId] = React.useState(
    clientSearch.clientSearchId,
  );
  const [clientId, setClientId] = React.useState(client.clientId);

  const { addOrSelect: addOrSelectClient } = useCreateClient({
    onError: async (error) => {
      if (
        error.response?.status !== 422 ||
        (error.response?.data as any)?.message?.includes('integrity constraint')
      ) {
        await analytics.track(
          ANALYTICS_EVENTS.CLIENT_SEARCH_ADD_FROM_OFFER_FAILED,
          {
            offerId: offer.id,
            offerType: offer.type,
            clientSearchId,
            clientId,
          },
        );
      }
    },
  });

  const { mutateAsync: createClientSearch } = useMutation(postAddClientSearch, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['clientSearches']);
    },
    onError: async (e: AxiosError) => {
      await analytics.track(
        ANALYTICS_EVENTS.CLIENT_SEARCH_ADD_FROM_OFFER_FAILED,
        {
          offerId: offer.id,
          offerType: offer.type,
          clientSearchId,
          clientId,
        },
      );
      defaultErrorToasts(e, t);
    },
  });
  const { mutateAsync: addOfferToClientSearch } = useMutation(
    postAddOfferToClientSearch,
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([
          'clientSearchesOffers',
          clientSearchId,
        ]);
        await analytics.track(
          ANALYTICS_EVENTS.CLIENT_SEARCH_ADD_FROM_OFFER_SUBMITTED,
          {
            offerId: offer.id,
            offerType: offer.type,
            clientSearchId,
            clientId,
          },
        );
      },
      onError: async (e: AxiosError) => {
        if (e.response?.status === 409) {
          displayErrorToast({
            title: t(
              'client-searches:addOfferToClientSearchDrawer.duplicate.title',
            ),
            body: t(
              'client-searches:addOfferToClientSearchDrawer.duplicate.body',
            ),
          });
        } else {
          await analytics.track(
            ANALYTICS_EVENTS.CLIENT_SEARCH_ADD_FROM_OFFER_FAILED,
            {
              offerId: offer.id,
              offerType: offer.type,
              clientSearchId,
              clientId,
            },
          );
          defaultErrorToasts(e, t);
        }
      },
    },
  );
  const createVariant = useVariantCreationFromOffer({
    offerId: offer.id,
    offerType: offer.type,
  });
  const { mutateAsync: changeStatus } = useMutation(
    postChangeClientSearchOfferStatus,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['clientSearchesOffers', clientSearchId]);
      },
    },
  );

  const submit = async () => {
    if (!canSubmit) {
      return;
    }
    const isNewClientFormCorrect = await client.submit();
    if (
      client.selection.value === ClientSelection.NEW &&
      !isNewClientFormCorrect
    ) {
      return;
    }

    setWasLastSubmitSuccess(undefined);
    setIsSubmitting(true);

    try {
      const clientId = await addOrSelectClient({
        client_search_client_email: undefined,
        client_search_client_first_name: client.new.data.first_name ?? '',
        client_search_client_id: client.clientId ?? '',
        client_search_client_last_name: client.new.data.last_name ?? '',
        client_search_client_phone_number: client.new.data.phone_number ?? '',
        client_search_client_type:
          client.selection.value === ClientSelection.NEW
            ? ClientType.NEW
            : ClientType.EXISTING,
      });
      setClientId(clientId);

      let clientSearchId = clientSearch.clientSearchId ?? '';
      if (clientSearch.selection.value === ClientSearchSelection.NEW) {
        const createClientSearchResponse = await createClientSearch({
          body: {
            title: clientSearch.new.value ?? '',
            description: '',
            client_id: clientId,
            views: [],
          },
        });
        clientSearchId = createClientSearchResponse.client_offer_search_id;
      }
      setClientSearchId(clientSearchId);

      const clientSearchOfferId = await addOfferToClientSearch({
        body: { offer_id: offer.id, offer_type: offer.type },
        clientSearchId: clientSearchId ?? '',
      });

      const variantId = await createVariant();

      await changeStatus({
        clientSearchId: clientSearchId,
        clientSearchOfferId:
          clientSearchOfferId.client_search_selected_offer_id,
        newState: OfferSearchStateEnum.PREPARING,
        offerVariantId: variantId,
      });

      setIsSubmitting(false);
      setWasLastSubmitSuccess(true);

      displaySuccessToast({
        title: t('client-searches:addOfferToClientSearchDrawer.success.title'),
        body: t('client-searches:addOfferToClientSearchDrawer.success.body'),
      });
    } catch {
      setIsSubmitting(false);
      setWasLastSubmitSuccess(false);
    }
  };

  const canSubmit = client.isValid && clientSearch.isValid && !isSubmitting;

  const clear = () => {
    setIsSubmitting(false);
    setWasLastSubmitSuccess(undefined);
    setClientSearchId(clientSearch.clientSearchId);
    setClientId(client.clientId);
  };

  return {
    submit,
    canSubmit,
    isSubmitting,
    wasLastSubmitSuccess,
    finalClientSearchId: clientSearchId,
    clear,
  };
};

export type UseAddToClientSearchSubmitData = ReturnType<
  typeof useAddToClientSearchSubmit
>;
