import { stringifyUrl } from 'query-string';

export function calculatePropertyIndexValue(
  estimatedPrice: number,
  realPrice: number,
) {
  return ((realPrice - estimatedPrice) / estimatedPrice) * 100;
}

export function shouldHideIndex(calculatedIndex: number) {
  return Math.abs(calculatedIndex) > 50;
}

export const generateAvmDetailsLink = ({ id }: { id: string }) => {
  return stringifyUrl({
    url: `/avm/details/${id}`,
  });
};

export const generateAvmShareLink = ({ shareId }: { shareId: string }) => {
  return window.location.origin + `/public/evaluation/${shareId}`;
};

export const generateAvmEvaluationFormLink = () => {
  return stringifyUrl({
    url: '/avm/valuate',
  });
};
